
const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })

function ApiException(message) {
    return new Error(message)
}

export class Api {
    constructor() {
        this.handlerApi = window.REACT_APP_CONTENT_HANDLER_API
        this.generationApi = window.REACT_APP_BANNER_GENERATION_API
        this.renderingApi = window.REACT_APP_BANNER_RENDERING_API
        this.platformApi = window.REACT_APP_PLATFORM_API
        this.jwt = undefined
    }

    setJwt(jwt) {
        this.jwt = jwt
    }

    async callApi(method, url, data = {}, resp = "json", authentRequired = true) {
        // Default options are marked with *
        let init = {
            method: method,
            cache: 'no-cache',
            redirect: 'error',
            headers: {}
        }

        if (method === "POST") {
            init["body"] = JSON.stringify(data)
            init["headers"]['Content-Type'] = 'application/json'
        }
        if (authentRequired === true) {
            init["headers"]["Authorization"] = "Bearer " + this.jwt
        }

        const response = await fetch(url, init);

        if (response.status !== 200) {
            throw ApiException("Response status error: " + response.status)
        }

        if (resp === "json") {
            return response.json()
        }

        if (resp === "blob") {
            return response.blob()
        }

        return response
    }

    async createFunnel(title, subtitle, cta, logo, image, color, font, fontSubtitle) {
        const base64lLogo = await toBase64(logo)
        let base64Image = undefined
        if (image) {
            base64Image = await toBase64(image)
        }

        return this.callApi(
            "POST",
            this.handlerApi + "/semrush/funnel",
            {
                title: title,
                subtitle: subtitle,
                cta: cta,
                logo: base64lLogo,
                image: base64Image,
                color: color,
                font: font,
                font_subtitle: fontSubtitle,
            }
        )
    }

    async generateBanner(funnelId, format) {
        return this.callApi("POST",this.generationApi + "/semrush/"+ funnelId + "/banner", {banner_format: format})
    }

    async varyBanner(funnelId, format, bannerId) {
        return this.callApi("POST",this.generationApi + "/semrush/"+ funnelId + "/banner/format", {banner_format: format, banner_id: bannerId})
    }

    async renderBanner(bannerId) {
        return this.callApi("GET",this.renderingApi + "/banner/"+ bannerId + "?semrush=true", {}, "blob", false)
    }

    async downloadBanner(bannerId) {
        return this.callApi("GET",this.platformApi + "/semrush/download/"+ bannerId, {}, "blob")
    }

    async getFonts() {
        return this.callApi("GET",this.platformApi + "/semrush/fonts", {})
    }

    async authentUser() {
        return this.callApi("GET",this.platformApi + "/semrush/verify-user", {})
    }

    async getUnsplashImages(term, page, perPage) {
        return this.callApi("GET",this.platformApi + "/semrush/unsplash/search/" + term + "?page=" + page + "&per_page=" + perPage)
    }

    async downloadUnsplashPhoto(photoId) {
        return this.callApi("POST",this.platformApi + "/semrush/unsplash/downloaded_photo", {id: photoId})
    }

    async addBannersToFavorite(bannersId, uniqueId) {
        return this.callApi("POST",this.platformApi + "/semrush/user-set", {
            "uuid": uniqueId,
            banners_id: bannersId
        })
    }

    async deleteBannersFromFavorite(bannersId, uniqueId) {
        return this.callApi("POST",this.platformApi + "/semrush/user-set/delete", {
            "uuid": uniqueId,
            banners_id: bannersId
        })
    }

    async getUserSets(currentPage) {
        return this.callApi("GET",this.platformApi + "/semrush/user-sets?page=" + currentPage)
    }

    async createContentFunnel(title, subtitle, cta, image, bannerId) {
        let base64Image = undefined
        if (image) {
            base64Image = await toBase64(image)
        }

        return this.callApi(
            "POST",
            this.handlerApi + "/semrush/funnel/vary-content",
            {
                title: title,
                subtitle: subtitle,
                cta: cta,
                image: base64Image,
                banner_id: bannerId,
            }
        )
    }

    async varyContent(funnelId) {
        return this.callApi("POST",this.generationApi + "/semrush/"+ funnelId + "/banner/content", )
    }
}

const api = new Api()

export default api