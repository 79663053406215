import React from "react";
import Checkbox from '@semcore/checkbox';

const style = {
    checkboxValue: {
        position: "absolute",
        right: "8px",
    },
    checkbox: {
        width: "173px",
        height: "34px",
        padding: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid #CCC",
        borderRadius: "4px",
        position: "relative",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
    },
    socialNetwork: {
        width: "34px",
        height: "34px",
        backgroundColor: "rgba(24, 119, 242, 0.1)",
        borderRadius: "4px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    name: {
        fontWeight: "500",
        fontSize: "12px",
    },
    description: {
        fontSize: "12px"
    },
}

const FormatCheckbox = ({ format, checked, onChange }) => {
    return (
        <Checkbox key={"format" + format.id} size="l" state="normal" style={style.checkbox}>
            <div style={{...style.socialNetwork, backgroundColor: format.color}}>
                <img alt="social logo" src={format.image} />
            </div>
            <Checkbox.Text>
                <div style={style.name}>{format.name}</div>
                <div style={style.description}>{format.description}</div>
            </Checkbox.Text>
            <Checkbox.Value
                id={format.id}
                style={style.checkboxValue}
                checked={checked}
                onChange={onChange}
            />
        </Checkbox>
    )
}

export default FormatCheckbox