import React, { useState, useEffect } from "react";
import Checkbox from '@semcore/checkbox';
import { Text } from '@semcore/typography';
import TabLine from '@semcore/tab-line';
import {getFormats} from "../../common/format";

const style = {
    formatContainer: {
        width: "400px",
    },
    platformContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    checkboxValue: {
        position: "absolute",
        right: "8px",
    },
    checkbox: {
        width: "173px",
        height: "34px",
        padding: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid #CCC",
        borderRadius: "4px",
        position: "relative",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
    },
    socialNetwork: {
        width: "34px",
        height: "34px",
        backgroundColor: "rgba(24, 119, 242, 0.1)",
        borderRadius: "4px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    name: {
        fontWeight: "500",
        fontSize: "12px",
    },
    description: {
        fontSize: "12px"
    },
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
    tablineContainer: {
        marginBottom: "18px",
    },
    tablineItem: {
        marginLeft: "16px",
    }
}

const Formats = ({input, error, formatType, setFormatType}) => {
    const formats = getFormats()

    const [checked, setChecked] = useState(
        formatType === "iab" ? {
            "IAB_300x250": false,
            "IAB_300x600": false,
            "IAB_728x90": false,
        } : {
            "FB_FEED": false,
            "FB_SQUARE": false,
            "TW_FEED": false,
            "TW_DMC": false,
            "LI_FEED": false,
            "INSTAGRAM_SQUARE": false,
        }
    )

    useEffect(() => {
        input.onChange(checked)
    }, [input, checked])

    return (
        <div style={style.formatContainer}>
            <Text size={400} tag="div" mb={1}>
                1. Select banner formats
            </Text>
            <Text size={200} tag="div" mb={1}>
                You can select one or all available social formats at the same time.
            </Text>
            {error && (<div style={style.error}> {error} </div>)}
            <TabLine size="m" underlined value={formatType}
                     onChange={(value) => {
                         setFormatType(value)
                         if (value === "iab") {
                             setChecked({
                                 "IAB_300x250": false,
                                 "IAB_300x600": false,
                                 "IAB_728x90": false,
                             })
                         } else {
                             setChecked({
                                 "FB_FEED": false,
                                 "FB_SQUARE": false,
                                 "TW_FEED": false,
                                 "TW_DMC": false,
                                 "LI_FEED": false,
                                 "INSTAGRAM_SQUARE": false,
                             })
                         }
                     }}
                     style={style.tablineContainer}>
                <TabLine.Item value="social">
                    Social media formats
                </TabLine.Item>
                <TabLine.Item value="iab" style={style.tablineItem}>
                    IAB display formats
                </TabLine.Item>
            </TabLine>
            <div style={style.platformContainer}>
               {formats[formatType].map(format => {
                    return (
                        <Checkbox key={"format" + format.id} size="l" state="normal" style={style.checkbox}>
                            <div style={{...style.socialNetwork, backgroundColor: format.color}}>
                                <img alt="social logo" src={format.image} />
                            </div>
                            <Checkbox.Text>
                                <div style={style.name}>{format.name}</div>
                                <div style={style.description}>{format.description}</div>
                            </Checkbox.Text>
                            <Checkbox.Value
                                id={format.id}
                                style={style.checkboxValue}
                                checked={checked[format.id]}
                                onChange={() => {
                                    setChecked(current => ({
                                        ...current,
                                        [format.id]: !checked[format.id]
                                    }))
                                }}
                            />
                        </Checkbox>
                    )
               })}
            </div>
        </div>
    )
}

export default Formats