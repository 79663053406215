import React, {useEffect, useState} from "react";
import * as Sentry from "@sentry/browser";
import WidgetEmpty, { getIconPath } from '@semcore/widget-empty'
import { Box, Flex } from '@semcore/flex-box'
import Button from '@semcore/button'
import {createUseStyles} from "react-jss";
import { Text } from '@semcore/typography';
import MathMinusM from '@semcore/icon/lib/MathMinus/s'
import Edit from '@semcore/icon/lib/Edit/s'
import FileDownload from '@semcore/icon/lib/FileDownload/s'
import ReloadM from '@semcore/icon/lib/Reload/m'
import {getHeightFromFormatForFavoritesBanner, getNameFromFormat} from "../../common/format";
import Pagination from '@semcore/pagination';
import Skeleton from '@semcore/skeleton';
import RepeatXS from '@semcore/icon/lib/Repeat/xs';
import { Error } from '@semcore/widget-empty'
import { NoData } from '@semcore/widget-empty';

const useStyles = createUseStyles({
    userSetContainer: {
        background: "#f5f5f5",
        borderRadius: "4px",
        position: "relative",
    },
    hoverActions: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(5,9,26, 0.8)",
        transition: ".5s ease",
        opacity: 0,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    button: {
        width: "160px",
    },
    bannerContainer: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        width: "fit-content",
        "&:hover $hoverActions": {
            opacity: 1,
        },
    },
    image: {
        maxWidth: "100%",
        maxHeight: "100%",
        "&:hover $hoverActions": {
            opacity: 1,
        },
    },
})


const Favorites = ({generationHandler, setGeneralError, setGeneralInfo, downloadBanner, setMode, setOriginalBanner, setUserSet, downloadedBanners, setDownloadedBanners, isAppInstalled}) => {
    const classes = useStyles()

    const [userSets, setUserSets] = useState([])
    const [retrieved, setRetrieved] = useState(false)
    const [error, setError] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [disableButtons, setDisabledButtons] = useState(false)

    useEffect(() => {
        generationHandler.getUserSets(currentPage)
            .then(data => {
                setUserSets(data["records"])
                setTotalPages(data["_metadata"]["page_count"])
                setRetrieved(true)
            })
            .catch(err => {
                setGeneralError("An error happened when trying to retrieve saved banners")
                Sentry.captureException(err)
                setError(true)
            })
    }, [generationHandler, setGeneralError, currentPage])

    const deleteFromFavorite = (bannersId, uniqueId) => {
        setDisabledButtons(true)
        generationHandler.deleteBannersFromFavorite(bannersId, uniqueId)
            .then(() => {
                let userSetIndex = undefined
                userSets.forEach((userSet, index) => {
                    if (userSet.id === uniqueId) {
                        userSetIndex = index
                    }
                })

                if (userSetIndex !== undefined) {
                    let banners = [
                        ...userSets[userSetIndex].banners
                    ]

                    banners = banners.filter(function(banner) {
                        return !bannersId.includes(banner.id)
                    })

                    let userSetsState = [
                        ...userSets,
                    ]

                    userSetsState[userSetIndex] = {
                        ...userSetsState[userSetIndex],
                        banners: banners,
                    }

                    if (banners.length === 0) {
                        userSetsState.splice(userSetIndex, 1)
                    }

                    setUserSets(userSetsState)
                }

                setGeneralInfo(
                    bannersId.length === 1 ? "The banner has been deleted from Favorites": "Banners have been deleted from Favorites"
                )
            }).catch(err => {
                setGeneralError("An error happened When trying to delete banners from favorites. Please try again.")
             })
            .finally(() => {
                setDisabledButtons(false)
            })
    }

    if (error === true) {
        return <Error description={"Try to reload widget. Error when trying to load favorite banners"}>
            <Box mt={4}>
                <Button>
                    <Button.Addon tag={RepeatXS} />
                    <Button.Text>Reload</Button.Text>
                </Button>
            </Box>
        </Error>
    }

    if (retrieved === false) {
        return (
            <Skeleton height={740} width={"100%"}>
                <rect x="0" y="0" rx="4" ry="4" height="350" width="100%" />
                <rect x="0" y="390" rx="4" ry="4" height="350" width="100%" />
            </Skeleton>
        )
    }

    // The page doesn't exist.
    if (totalPages < currentPage) {
        return <NoData type="nothing-found" description="Reload the first page">
            <Box mt={4}>
                <Button onClick={() => {
                    setRetrieved(false)
                    setCurrentPage(1)
                }}>
                    <Button.Addon tag={ReloadM} />
                    <Button.Text>Reload</Button.Text>
                </Button>
            </Box>
        </NoData>
    }

    // No banner have been saved
    if (userSets.length === 0) {
        return (
            <Box>
                <WidgetEmpty icon={getIconPath('nothing-found')} mt={60}>
                    <WidgetEmpty.Title>Saved banners will be displayed here</WidgetEmpty.Title>
                    <WidgetEmpty.Description>
                        Start by generating banners and save your favorites one!
                    </WidgetEmpty.Description>
                </WidgetEmpty>
            </Box>
        )
    }

    return (
        <div>
            {userSets.map(userSet => {
                return (
                    <Box key={userSet.id} ml={6} p={4} mb={10} className={classes.userSetContainer}>
                        <Flex mb={3} justifyContent="space-between" alignItems="center">
                            <Flex>
                                <Text size={400} tag="div">
                                    Banner set {userSet.id.split("-")[0]}
                                </Text>
                                <Button
                                    ml={5}
                                    size="m"
                                    theme="muted"
                                    use="secondary"
                                    type="button"
                                    disabled={disableButtons}
                                    onClick={e => {
                                        const bannersId = userSet.banners.map((banner) => {
                                            return banner.id
                                        })

                                        deleteFromFavorite(bannersId, userSet.id)
                                        e.preventDefault()
                                    }}
                                >
                                    <Button.Addon tag={MathMinusM} />
                                    <Button.Text>Remove set from Favorite</Button.Text>
                                </Button>
                            </Flex>
                        </Flex>
                        <Flex flexWrap="wrap">
                            {userSet.banners.map(banner => {
                                return (
                                    <>
                                        <Box key={"banner-" + banner.id} mb={5} ml={3} style={banner.format === "IAB_728x90" ? {width: "100%"} : {}}>
                                            <Text mb={1} color="gray20" size={100}>{getNameFromFormat(banner.format)}</Text>
                                            <div className={classes.bannerContainer} key={"banner-container-" + banner.id} style={
                                                {
                                                    height: getHeightFromFormatForFavoritesBanner(banner.format),
                                                }
                                            }>
                                                <img alt={"banner "+ banner.format} src={window.REACT_APP_S3_DOMAIN + userSet.id + "/" + banner.id + ".jpg"} className={classes.image} />
                                                <div className={classes.hoverActions}>
                                                    <Button
                                                        className={classes.button}
                                                        size="m"
                                                        use="secondary"
                                                        theme="success"
                                                        type="button"
                                                        disabled={disableButtons}
                                                        onClick={e => {
                                                            downloadBanner(banner.id)
                                                            e.preventDefault()
                                                        }}
                                                    >
                                                        <Button.Addon tag={FileDownload} />
                                                        <Button.Text>Download</Button.Text>
                                                    </Button>
                                                    <Button
                                                        className={classes.button}
                                                        size="m"
                                                        use="secondary"
                                                        theme="success"
                                                        type="button"
                                                        disabled={disableButtons}
                                                        onClick={e => {
                                                            deleteFromFavorite([banner.id], userSet.id)
                                                            e.preventDefault()
                                                        }}
                                                        mt={5}
                                                    >
                                                        <Button.Addon tag={MathMinusM} />
                                                        <Button.Text>Remove from favorite</Button.Text>
                                                    </Button>
                                                    <Button
                                                        className={classes.button}
                                                        size="m"
                                                        use="secondary"
                                                        theme="success"
                                                        type="button"
                                                        disabled={disableButtons}
                                                        onClick={e => {
                                                            setMode("format")
                                                            setOriginalBanner(banner)
                                                            setUserSet(userSet)
                                                            e.preventDefault()
                                                        }}
                                                        mt={banner.format === "IAB_728x90"? 0 : 5}
                                                    >
                                                        <Button.Addon tag={Edit} />
                                                        <Button.Text>Edit format</Button.Text>
                                                    </Button>

                                                    <Button
                                                        className={classes.button}
                                                        size="m"
                                                        use="secondary"
                                                        theme="success"
                                                        type="button"
                                                        disabled={disableButtons}
                                                        onClick={e => {
                                                            setMode("content")
                                                            setOriginalBanner(banner)
                                                            setUserSet(userSet)
                                                            e.preventDefault()
                                                        }}
                                                        mt={5}
                                                    >
                                                        <Button.Addon tag={Edit} />
                                                        <Button.Text>Edit Content</Button.Text>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Box>
                                    </>
                                )
                            })}
                        </Flex>
                    </Box>
                )
            })}

            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    onCurrentPageChange={(pageNumber)=> {
                        setRetrieved(false)
                        setCurrentPage(pageNumber)
                    }}
                    totalPages={totalPages}
                />
            )}
        </div>
    )
}

export default Favorites