import React, {useState} from 'react';
import Select from '@semcore/select';
import { Text } from '@semcore/typography';
import { InputSearch } from '@semcore/select';

const style = {
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
    optional: {
        color: "#757575"
    },
    label: {
        display: "flex",
        justifyContent: "space-between",
    },
    closeButton: {
        width: "14px",
        height: "14px",
        position: "absolute",
        right: "25px",
    }
}

const AutoComplete = ({formInput, options, optionKey, optionValue, text}) => {
    const [error, setError] = useState("")
    const [filter, setFilter] = useState('');
    const filteredOptions = options.filter(option => option[optionValue].toLowerCase().includes(filter.toLowerCase()))
    const [value, setValue] = useState(null)

    const handleChange = (value) => {
        const res = options.filter(option => option[optionValue].toLowerCase() === value.toLowerCase())
        if (res.length !== 1) {
            setError("The option doesn't exist")
        } else {
            setError("")
            setValue(value)
            formInput.onChange(res[0][optionKey])
        }
    };

    return (
        <div>
            {error && (<div style={style.error}> {error} </div>)}
            <Text  size={200} tag="label" mb={1} htmlFor="Select font" style={style.label}>
                <span>
                    { text }
                </span>
                <span style={style.optional}>
                    (optional)
                </span>
            </Text>
            <Select placeholder="Select font" value={value} onChange={handleChange}>
                <Select.Trigger w={400} size="l">
                    <Select.Trigger.Text>
                        <span>{value}</span>
                        <span style={style.closeButton} onClick={(e)=>{
                            setValue(null)
                            formInput.onChange(undefined)
                            e.stopPropagation()
                        }}>
                            <svg viewBox="0 0 24 24">
                                <path d="M12 9.943l3.921-3.921a1 1 0 011.414 0l.643.643a1 1 0 010 1.414L14.057 12l3.921 3.921a1 1 0 010 1.414l-.643.643a1 1 0 01-1.414 0L12 14.057l-3.921 3.921a1 1 0 01-1.414 0l-.643-.643a1 1 0 010-1.414L9.943 12 6.022 8.079a1 1 0 010-1.414l.643-.643a1 1 0 011.414 0L12 9.943z"/>
                            </svg>
                        </span>
                    </Select.Trigger.Text>
                </Select.Trigger>
                <Select.Popper>
                    <InputSearch value={filter} onChange={setFilter} placeholder="Search"/>
                    <Select.List hMax={'224px'}>
                        {filteredOptions.length ? (
                            filteredOptions.map((option) => (
                                <Select.Option value={option[optionValue]} key={option[optionKey]}>
                                    {option[optionValue]}
                                </Select.Option>
                            ))
                        ) : (
                            <Select.OptionHint key="Nothing">Nothing found</Select.OptionHint>
                        )}
                    </Select.List>
                </Select.Popper>
            </Select>
        </div>
    )
}

export default AutoComplete