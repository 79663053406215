import React, {useState} from "react";
import {ChromePicker} from "react-color";
import chroma from "chroma-js"

const style = {
    colorpickerArea: {
        position: "relative",
    },
    colorPickerCard: {
        height: "54px",
        backgroundColor:"#FFFFFF",
        border: "1px solid #CCC",
        boxSizing: "border-box",
        borderRadius: "4px",

        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        position: "relative"
    },
    swatch: {
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        border: "1px solid #D5DBE6",
        width: "34px",
        height: "34px",
        marginLeft: "10px",
        marginRight: "10px",
    },
    colorText: {
        fontWeight: "500",
        fontSize: "12px",
        color: "#333333",
    },
    optionalText: {
        fontSize: "12px",
        color: "#333333",
    },
    removeButton: {
        minWidth: "auto",
        float: "left",
        position: "absolute",
        right: "10px",
        width: "20px",
        height: "20px",
    },
    popoverTop: {
        position: "absolute",
        zIndex: "2",
        top: "-260px",
        left: "0px",
    },
    cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
}

const convertColor = hexColor => {
    let color = hexColor
    let opacity = 1

    if (color.startsWith("#")) {
        color = color.substr(1)
    }

    if (color.length === 8) {
        opacity = Math.round((parseInt(color.substr(6, 2), 16) / 255) * 100) / 100
        color = color.substr(0, 6)
    }

    return {
        hexColor: color.toUpperCase(),
        opacity: opacity,
    }
}


const ColorPicker = ({
    handleColorChange,
    handleColorChangeComplete = null,
    color = ""
}) => {
    const extractedColor = convertColor(color)
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const swatchStyle =
        color
            ? {
                backgroundColor: color,
                border: "none",
            }
            : {}

    return (
        <div style={style.colorpickerArea}>
            {displayColorPicker && (
                <div
                    style={style.popoverTop}
                    onKeyPress={e => {
                        // When pressing enter, that closes the picker
                        // That prevents from submitting a form
                        if (e.which === 13) {
                            e.preventDefault()
                            setDisplayColorPicker(false)
                        }
                    }}>
                    <div style={style.cover} onClick={() => setDisplayColorPicker(false)} />
                    <ChromePicker
                        disableAlpha={true}
                        color={color}
                        onChange={color => {
                            handleColorChange(chroma(color.rgb).hex())
                        }}
                        onChangeComplete={color => {
                            handleColorChangeComplete && handleColorChangeComplete(chroma(color.rgb).hex())
                        }}
                    />
                </div>
            )}
            <div style={style.colorPickerCard} onClick={() => {setDisplayColorPicker(true)}}>
                <div style={{...style.swatch, ...swatchStyle}} />
                <span>
                    {
                        color
                            ? <div style={style.colorText}>HEX: {extractedColor.hexColor}</div>
                            : <>
                                <div style={style.colorText}>Click to define a color</div>
                                <div style={style.optionalText}>(Optional)</div>
                            </>
                    }
                </span>
                {color && (
                    <div
                        style={style.removeButton}
                        onClick={(e) => {
                            handleColorChange("")
                            handleColorChangeComplete && handleColorChangeComplete("")
                            e.stopPropagation()
                        }}
                    >
                        <svg viewBox="0 0 24 24">
                            <path d="M12 9.943l3.921-3.921a1 1 0 011.414 0l.643.643a1 1 0 010 1.414L14.057 12l3.921 3.921a1 1 0 010 1.414l-.643.643a1 1 0 01-1.414 0L12 14.057l-3.921 3.921a1 1 0 01-1.414 0l-.643-.643a1 1 0 010-1.414L9.943 12 6.022 8.079a1 1 0 010-1.414l.643-.643a1 1 0 011.414 0L12 9.943z"/>
                        </svg>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ColorPicker