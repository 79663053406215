import React, { useState, useEffect, useCallback } from 'react';
import FullscreenModal from '@semcore/fullscreen-modal';
import Input from '@semcore/input';
import {createUseStyles} from "react-jss";
import Button from '@semcore/button';
import { Text } from '@semcore/typography';
import * as Sentry from "@sentry/browser"
import Macy from "macy"

const useStyles = createUseStyles({
    keyword: {
        width: "100%"
    },
    fullScreenContainer: {
        maxWidth: "720px",
        width: "100%",
        margin: "60px auto",
    },
    form: {
        display: "flex",
        width: "100%"
    },
    searchButton: {
        marginLeft: "20px",
    },
    contentContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    title: {
        display: "flex",
        alignItems: "center",
        "& > img": {
            marginLeft: "20px",
            width: "140px"
        }
    },
    unsplashPhoto: {
        cursor: "pointer",
        display: "block",
        width: "284px",
        border: "2px solid #FFF",
        position: "relative",
        "& img": {
            width: "100%",
            display: "block",
        },
        "& a": {
            position: "absolute",
            color: "white",
            padding: "0 4px",
            bottom: "10px",
            left: "16px",
        },
        "&:hover $hoverActions": {
            visibility: "visible",
            opacity: 1,
        },
    },
    picturesArea: {
        marginTop: "20px",
        width: "902px",
        display: "flex",
        flexFlow: "row wrap",
        "& > div": {
            marginRight: "25px",
            marginBottom: "25px",
        },
        "& > div:nth-child(3n)": {
            marginRight: 0,
        },
    },
    hoverActions: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(5,9,26, 0.8)",
        transition: ".5s ease",
        opacity: 0,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",
    },
    selectButton: {
        width: "100px",
        margin: "auto",
    },
    error: {
        color: "#ff7f00",
    }
})

const Unsplash = ({apiHandler, hidden, setHidden, handlePicturesSelected}) => {
    const classes = useStyles()
    const perPage = 12

    const [keyword, setKeyword] = useState("")
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchLoaded, setSearchLoaded] = useState(false)
    const [searchSubmitted, setSearchSubmitted] = useState(false)
    const [page, setPage] = useState(1)
    const [pictureFetching, setPictureFetching] = useState(false)
    const [pictures, setPictures] = useState([])
    const [chosenPictures, setChosenPictures] = useState({})

    const handleFeaturedWord = term => {
        if (!searchSubmitted) {
            setKeyword(term)
            setPage(1)
            setSearchSubmitted(true)
        }
    }

    const cleanModalState = () => {
        setSearchLoading(false)
        setSearchLoaded(false)
        setSearchSubmitted(false)
        setChosenPictures({})
        setPictureFetching(false)
        setKeyword("")
        setPictures([])
        setPage(1)
    }

    const handleSave = useCallback(() => {
        cleanModalState()
        handlePicturesSelected(Object.values(chosenPictures))
    }, [handlePicturesSelected, chosenPictures])

    const asyncDownloadUnsplashPhoto = photoId => {
        apiHandler.downloadUnsplashPhoto(photoId)
            .catch(err => {
            Sentry.captureException(err)

            if (process.env.NODE_ENV === "development") {
                console.error(err)
            }
        })
    }

    useEffect(() => {
        function fetchUnsplash(term) {
            setSearchLoading(true)
            setSearchLoaded(false)

            apiHandler.getUnsplashImages(term, page, perPage)
                .then(result => {
                    setPictures(pictures => {
                        if (page > 1) {
                            return [...pictures, ...result.images]
                        }

                        return result.images
                    })
                    setSearchSubmitted(false)
                    setSearchLoading(false)
                    setSearchLoaded(true)
                })
                .catch(err => {
                    Sentry.captureException(err)

                    setSearchSubmitted(false)
                    setSearchLoading(false)
                    setSearchLoaded(true)
                })
        }

        if (searchSubmitted && !pictureFetching) {
            fetchUnsplash(keyword)
        }
    }, [keyword, searchSubmitted, pictureFetching, page, perPage, apiHandler])

    useEffect(() => {
        if (searchLoaded && pictures.length > 0) {
            Macy({
                container: "#masonry",
                trueOrder: false,
                waitForImages: false,
                columns: 3,
                margin: 25,
            })
        }
    }, [searchLoaded, pictures])

    useEffect(() => {
        if (page > 1) {
            setSearchSubmitted(true)
        }
    }, [page])

    useEffect(() => {
        if (Object.values(chosenPictures).length >= 1) {
            handleSave()
        }
    }, [chosenPictures, handleSave])


    return (
        <FullscreenModal hidden={hidden} onClose={() => {
            cleanModalState()
            setHidden(true)}
        }>
            <FullscreenModal.Close />
            <FullscreenModal.Back>Go to back to main page</FullscreenModal.Back>
            <FullscreenModal.Header title="Import from Unsplash"/>
            <FullscreenModal.Body>
                <div className={classes.fullScreenContainer}>
                    <div className={classes.form}>
                        <Input className={classes.keyword}  size="xl" state="normal" onChange={e => setKeyword(e.target.value)}>
                            <Input.Value value={keyword} placeholder="Search free high-resolutions photos" />
                        </Input>
                        <Button className={classes.searchButton} size="xl" use="primary" theme="success" type="button" loading={searchLoading} onClick={e => {
                            if (!keyword) {
                                return
                            }

                            setPage(1)
                            setSearchSubmitted(true)
                        }}>
                            Search
                        </Button>
                    </div>
                    <div className={classes.contentContainer}>
                        {pictures.length === 0 && searchLoaded && (
                            <Text size={300} tag="div" mt={1} className={classes.error}>
                                Sorry, no image found with this keyword.
                            </Text>
                        )}
                        {pictures.length > 0 ? (
                            <>
                                <div id="masonry" className={classes.picturesArea}>
                                    {pictures.map(photo => (
                                        <div key={"photo" + photo.id} className={classes.unsplashPhoto}>
                                            <img src={photo.urls.small} alt="" />
                                            <div className={classes.hoverActions}>
                                                <a href={photo.user.link + "?utm_source=abyssale&utm_medium=referral"} target="_blank" rel="noopener noreferrer">
                                                    <Text size={200} tag="div">
                                                        {photo.user.name}
                                                    </Text>
                                                </a>
                                                <Button className={classes.selectButton} size="l" use="secondary" theme="success" type="button" onClick={() => {
                                                    setPictureFetching(true)
                                                    fetch(photo.urls.regular)
                                                        .then(fileResponse => fileResponse.blob())
                                                        .then(blobFile => {
                                                            let fullPicture = new File([blobFile], photo.urls.regular, {
                                                                type: blobFile.type,
                                                            })

                                                            asyncDownloadUnsplashPhoto(photo.id)
                                                            setPictureFetching(false)

                                                            setChosenPictures(currentChosenPictures => {
                                                                if (photo.id in chosenPictures) {
                                                                    return currentChosenPictures
                                                                }

                                                                return {
                                                                    ...currentChosenPictures,
                                                                    [photo.id]: {
                                                                        id: photo.id,
                                                                        thumb: photo.urls.thumb,
                                                                        file: fullPicture,
                                                                    },
                                                                }
                                                            })
                                                        })
                                                        .catch(err => {
                                                            Sentry.captureException(err)

                                                            if (process.env.NODE_ENV === "development") {
                                                                console.log(err)
                                                            }

                                                            setPictureFetching(false)
                                                        })
                                                }}>
                                                    <Button.Text>Select Picture</Button.Text>
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {pictures.length % perPage === 0 && (
                                    <div>
                                        <Button
                                            size="xl"
                                            use="primary"
                                            theme="info"
                                            mb={5}
                                            onClick={() => {
                                                setPage(page + 1)
                                            }}
                                            loading={searchLoading}
                                        >
                                            Load more items
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) :(
                            <>
                                <Text className={classes.title} size={500} tag="div" mb={4} mt={10}>
                                    Search photos on <img src="/images/unsplash-logo.svg" alt="Unsplash" />
                                </Text>
                                <Text size={300} tag="div">
                                    We partnered with Unsplash.com - The internet's source of free for commercial usage pictures.
                                </Text>
                                <Text size={300} tag="div" mt={5} mb={10}>
                                    Try with one of these:&nbsp;&nbsp;
                                    <Button size="l" use="secondary" theme="muted" type="button" ml={3} onClick={() => {handleFeaturedWord("business")}}>business</Button>
                                    <Button size="l" use="secondary" theme="muted" type="button" ml={3} onClick={() => {handleFeaturedWord("people")}}>people</Button>
                                    <Button size="l" use="secondary" theme="muted" type="button" ml={3} onClick={() => {handleFeaturedWord("forest")}}>forest</Button>
                                    <Button size="l" use="secondary" theme="muted" type="button" ml={3} onClick={() => {handleFeaturedWord("landscape")}}>landscape</Button>
                                </Text>
                                <img src="/images/unsplash-empty-state.png" alt="Unsplash example pictures" />
                            </>
                        )}
                    </div>
                </div>
            </FullscreenModal.Body>
        </FullscreenModal>
    )
}

export default Unsplash