import React, {useEffect, useState} from 'react';
import Maintenance from "./views/components/Maintenance"
import { BrowserRouter } from "react-router-dom"
import Spin from '@semcore/spin'
import Dashboard from "./views/components/Dashboard";

const style = {
    main: {
        maxWidth: "1122px",
        margin: "60px auto",
    }
}
function App() {
    const isSiteInMaintenance = window.REACT_APP_IS_MAINTENANCE === 'true'
    const [isAppReady, setIsAppReady] = useState(false)

    useEffect(()=>{
        const updateAppReady =  () => { setIsAppReady(true) }

        window.addEventListener("smAppReady", updateAppReady)

        return () => { return window.removeEventListener("smAppReady", updateAppReady)}
    }, [])

    if (!window.smAppReady && isAppReady === false && process.env.NODE_ENV === "production") {
        return <Spin centered size="m" theme="dark" />
    }

    return (
        <BrowserRouter>
            <div style={style.main}>
                {isSiteInMaintenance? (
                    <Maintenance />
                ) : (
                    <Dashboard />
                )}
            </div>
        </BrowserRouter>
    );
}

export default App;
