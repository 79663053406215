import FormatCheckbox from "../Form/FormatCheckbox";
import React, {useEffect, useState} from "react";

const EditFormatCheckboxes = ({input, formats}) => {
    const [checked, setChecked] = useState({})

    useEffect(() => {
        input.onChange(checked)
    }, [input, checked])

    return <>
        {formats.map(format => {
            return <FormatCheckbox
                key={format.id}
                format={format}
                checked={ format.id in checked && checked[format.id] === true }
                onChange={()=> {
                    setChecked({
                        ...checked,
                        [format.id]: !(format.id in checked && checked[format.id] === true)
                    })
                }}
            />
        })}
    </>
}

export default EditFormatCheckboxes