import React from "react";
import {createUseStyles} from "react-jss";
import { Box } from '@semcore/flex-box'
import { Text } from '@semcore/typography'

const useStyles = createUseStyles({
    originalBannerContainer: {
        padding: "24px",
        border: "1px solid #CCCCCC",
        borderRadius: "2px",
    },
    imageContainer: {
        display: "flex",
        alignItems:"center",
        justifyContent: "center",
        "&>img": {
            maxHeight: "150px",
            maxWidth: "350px",
        }
    },
})

const BannerPreview = ({userSet, originalBanner}) => {
    const classes = useStyles()

    return (
        <div className={classes.originalBannerContainer}>
            <Box mb={3}>
                <Text mb={2} size={400} tag="div">Original banner</Text>
                <Text color="gray" size={200} tag="div">Preview of your original banner</Text>
            </Box>
            <div className={classes.imageContainer}>
                <img alt="Original banner" src={window.REACT_APP_S3_DOMAIN + userSet.id + "/" + originalBanner.id + ".jpg"} />
            </div>
        </div>
    )
}

export default BannerPreview