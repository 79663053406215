import React, {useEffect, useState} from 'react'
import { Box, Flex } from '@semcore/flex-box';
import ReactDropzone from "react-dropzone"
import Button from '@semcore/button';
import QuestionAltXS from '@semcore/icon/lib/QuestionAlt/xs'
import Tooltip from '@semcore/tooltip';
import Trash from '@semcore/icon/lib/Trash/xs'
import Unsplash from "./Unsplash";
import { Text } from '@semcore/typography';

const style = {
    dragText: {
        marginBottom: "12px",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center"
    },
    dropZoneSection: {
        position: "relative",
        transition: "all 0.5s",
        "&:focus": {
            outline: "none",
        },
        "&:hover": {
            borderStyle: "dotted",
        },
    },
    imagePreview: {
        maxWidth: "90%",
        maxHeight: "90%",
    },
    closeButton: {
        width: "20px",
        height: "20px",
        position: "absolute",
        right: "0px",
        top: "4px",
        cursor: "pointer",
    },
    imageContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
    tooltip: {
        position: "absolute",
        right: "5px",
        bottom: "5px",
        cursor: "help"
    },
}


const mimeTypeError = "Invalid file type."
const fileSizeError = "The file you have selected is too big."

const DropFile = ({
      title="Drag and drop your file or",
      containerStyle = {},
      input,
      acceptedMimeTypes = [],
      maxFilesize = 5000000, // 5mb
      apiHandler,
      activeUnsplash = false,
}) => {
    const [error, setError] = useState("")
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        input.onChange(uploadedFiles)
    }, [input, uploadedFiles])

    const removeFiles = () => {
        setUploadedFiles([])
    }

    const handleDropAccepted = acceptedFiles => {
        setError("")
        for (const acceptedFile of acceptedFiles) {
            const objectURL = window.URL.createObjectURL(acceptedFile)

            Object.assign(acceptedFile, {
                additionalProperties: {
                    preview: objectURL,
                    source: "computer",
                    // mandatory to validate it inside yup
                    // as it is removed during casting
                    size: acceptedFile.size,
                    type: acceptedFile.type,
                },
            })

            setUploadedFiles(uploadedFiles => [...uploadedFiles, acceptedFile])
        }
    }

    const handleDropRejected = fileRejections => {
        for (const fileRejection of fileRejections) {
            if (!acceptedMimeTypes.includes(fileRejection.file.type)) {
                setError(mimeTypeError)
                return
            }

            if (fileRejection.file.size > maxFilesize) {
                setError(fileSizeError)
                return
            }
        }
    }

    return (
        <>
            {uploadedFiles.length > 0 ? (
                <Flex style={{...style.dropZoneSection, ...containerStyle}} justifyContent="space-evenly" alignItems="center">
                    {uploadedFiles.map(file => (
                        <div style={style.imageContainer} key={file.additionalProperties.preview}>
                            <img alt="preview" src={file.additionalProperties.preview} style={style.imagePreview}/>
                            <div style={style.closeButton} onClick={()=> {removeFiles()}}>
                                <Trash />
                            </div>
                        </div>
                    ))}
                </Flex>
            ) : (
                <ReactDropzone
                    onDropAccepted={handleDropAccepted}
                    onDropRejected={handleDropRejected}
                    accept={acceptedMimeTypes}
                    maxSize={maxFilesize}
                    multiple={false}
                >
                    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                        return (
                            <Flex style={{...style.dropZoneSection, ...containerStyle}} {...getRootProps()} justifyContent="space-evenly" alignItems="center">
                                <input {...getInputProps()} />
                                {!isDragActive && (
                                    <>
                                        <Box>
                                            <div style={style.dragText}>{title}</div>
                                            <Button theme="info" use="primary">
                                                Browse your computer
                                            </Button>
                                            {activeUnsplash === true && (
                                                <>
                                                    <Text size={100} ml={2} mr={2}>or</Text>
                                                    <Button onClick={(e) => {
                                                        setHidden(false)
                                                        e.stopPropagation()
                                                    }}>
                                                        Select from Unsplash
                                                    </Button>
                                                </>
                                            )}
                                            {error && (
                                                <div style={style.error}>{error}</div>
                                            )}
                                            <Tooltip
                                                interaction="hover"
                                                placement="top"
                                                theme="default"
                                                style={style.tooltip}
                                                title={<>
                                                    <div>Max file size: 5mb</div>
                                                    <div>Authorized file type:
                                                        {acceptedMimeTypes.includes("image/svg+xml") ? " svg": ""}
                                                        {acceptedMimeTypes.includes("image/png") ? " png ": ""}
                                                        {acceptedMimeTypes.includes("image/jpeg")? " jpeg ": ""}
                                                    </div>
                                                </>}
                                            >
                                                <QuestionAltXS color="stone"/>
                                            </Tooltip>
                                        </Box>
                                    </>
                                )}
                            </Flex>
                        )
                    }}
                </ReactDropzone>
            )}
            <Unsplash hidden={hidden} setHidden={setHidden} apiHandler={apiHandler} handlePicturesSelected={pictures => {
                setHidden(true)

                for (const picture of pictures) {
                    const newPicture = picture.file

                    Object.assign(newPicture, {
                        additionalProperties: {
                            preview: picture.thumb,
                            source: "unsplash",
                            // mandatory to validate it inside yup
                            // as it is removed during casting
                            size: newPicture.size,
                            type: newPicture.type,
                        },
                    })

                    setUploadedFiles(uploadedFiles => [...uploadedFiles, newPicture])
                }
            }}/>
        </>
    )
}

export default DropFile