import React from 'react';
import { Text } from '@semcore/typography';
import { Flex } from '@semcore/flex-box';
import ProgressBar from '@semcore/progress-bar';
import Tooltip from '@semcore/tooltip';
import QuestionAltXS from '@semcore/icon/lib/QuestionAlt/xs'

const style = {
    tooltip: {
        marginLeft: "10px",
        cursor: "help"
    },
    container:{
        position:"relative"
    },
    progressBar: {
        position: "absolute",
        right: "0px"
    }
}

const BannerCounter = ({bannerCount = 0, maxBanners = 3}) => {
    return (
        <Flex direction="column" w={"100%"} style={style.container}>
            <Flex mb={1}>
                <Text size={100}>Downloaded banners</Text>
                <Tooltip
                    interaction="hover"
                    placement="top"
                    theme="default"
                    style={style.tooltip}
                    title={"Your trial free banner download limits"}
                >
                    <QuestionAltXS color="stone"/>
                </Tooltip>
                <Flex alignItems="center" style={style.progressBar}>
                    <Text size={100} ml={1} bold>
                        {bannerCount}<Text color="gray60">/{maxBanners}</Text>
                    </Text>
                </Flex>
            </Flex>
            {/* Multiplied by 34 is 1/3 rounded up */}
            <ProgressBar size="s" value={bannerCount * 34} theme="#cccccc">
                <ProgressBar.Value theme="denim-blue" />
            </ProgressBar>
        </Flex>
    )
}


export default BannerCounter