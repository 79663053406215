import Breadcrumbs from '@semcore/breadcrumbs'
import { Form } from 'react-final-form'
import { Box, Flex } from '@semcore/flex-box'
import {createUseStyles} from "react-jss"
import React, {useState} from "react"
import {getTypeByFormat} from "../../../common/format";
import Button from '@semcore/button'
import {object, string} from "yup";
import * as Sentry from "@sentry/browser";
import WidgetEmpty, { getIconPath } from '@semcore/widget-empty'
import Generations from "../Banners/Generations";
import BannerPreview from "./BannerPreview";
import Content from "../Form/Content";

const useStyles = createUseStyles({
    generateButton: {
        width:"100%",
    },
})

const maxPrimaryWhenSecondary = 25
const maxCharacters = 90

let schema = object().shape({
    title: string()
        .trim()
        .min(3, "At least 3 characters are required to make convey your message")
        .test({
            name: "title_length_when_secondary",
            params: { maxPrimaryWhenSecondary },
            message: `The title text is longer than ${maxPrimaryWhenSecondary} characters. You can't have a body.`,
            test: function (value) {
                if (!this.parent.subtitle || this.parent.subtitle === "") {
                    return true
                }

                if (!value) {
                    return true
                }

                return value.length <= maxPrimaryWhenSecondary
            },
        })
        .test({
            name: "total_length",
            params: { maxCharacters },
            message: `Primary and subtitle text must have a maximum length of ${maxCharacters} characters.`,
            test: function (value) {
                if (!value) {
                    return true
                }

                let subtitle_length = 0
                if (this.parent.subtitle) {
                    subtitle_length = this.parent.subtitle.length
                }

                return value.length + subtitle_length <= maxCharacters
            },
        }),
    subtitle: string()
        .trim()
        .test("minimum_when_filled", "At least 3 characters are required to make convey your message.", function (value) {
            if (!value) {
                return true
            }

            return value.length >= 3
        }),
    cta: string()
        .trim()
        .test("minimum_when_filled", "The Call to action(CTA) must be between 3 and 15 characters.", function (value) {
            if (this.parent.formatType !== "iab") {
                return true
            }

            if (!value) {
                return true
            }

            return value.length >= 3 && value.length <=15
        }),
}).test("at_least_one_input_filled", "At least one input must be filled.", function (object) {
    if (object.title !== undefined) {
        return true
    }

    if (object.subtitle !== undefined) {
        return true
    }

    if (object.cta !== undefined) {
        return true
    }

    if (object.image !== undefined) {
        return true
    }

    return false
})

const EditContent = ({ generationHandler, setMode, userSet, originalBanner, setGeneralError, setGeneralInfo, downloadBanner, fonts }) => {
    const classes = useStyles()
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [errorTitle, setErrorTitle] = useState("")
    const [contentGenerations, setContentGenerations] = useState({})

    const type = getTypeByFormat(originalBanner.format)

    return (
        <>
            <Breadcrumbs mb={6}>
                <Breadcrumbs.Item onClick={() => {setMode("favorites")}}>
                    Favorites
                </Breadcrumbs.Item>
                <Breadcrumbs.Item active>
                    Edit content
                </Breadcrumbs.Item>
            </Breadcrumbs>

            <Form onSubmit={(data) => {
                setDisableSubmit(true)
                setErrorTitle("")

                schema.validate({
                    title: ("title" in data) || data["title"] !== "" ? data["title"]: "",
                    subtitle: ("subtitle" in data) || data["subtitle"] !== "" ? data["subtitle"]: "",
                    cta: ("cta" in data) || data["cta"] !== "" ? data["cta"]: "",
                    image: "image" in data && data["image"].length > 0 ? data["image"][0] : undefined,
                }, { abortEarly: false })
                    .then(() => {
                        const title = !("title" in data) || data["title"] !== "" ? data["title"]: undefined
                        const subtitle = !("subtitle" in data) || data["subtitle"] !== "" ? data["subtitle"]: undefined
                        const cta = !("cta" in data) || data["cta"] !== "" ? data["cta"]: undefined
                        let image = "image" in data && data["image"].length > 0 ? data["image"][0] : undefined

                        generationHandler.handleContentGeneration(title, subtitle, cta, image, originalBanner.id, userSet.id, originalBanner.format, setContentGenerations)
                            .then(data => {
                                setDisableSubmit(false)
                            })
                            .catch(err => {
                                Sentry.captureException(err)
                                setGeneralError("An error happened during the generation. Please try again.")
                                setDisableSubmit(false)
                            })
                    })
                    .catch(function (err) {
                        err.inner.forEach((innerError) => {
                            if (innerError.path === "title" || innerError.path === "subtitle") {
                                setErrorTitle(innerError.errors.join())
                            } else {
                                setGeneralError(innerError.errors.join())
                            }
                        });

                        setDisableSubmit(false)
                    })
            }}>
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <Flex wMax={1122}>
                            <Box w={400}>
                                <BannerPreview originalBanner={originalBanner} userSet={userSet} />

                                <Box mt={4} w={400}>
                                    <Content
                                        fonts={fonts}
                                        errorTitle={errorTitle}
                                        apiHandler={generationHandler}
                                        formatType={type}
                                        disableFont={true}
                                        disableTitleNumber={true}
                                        disableImage={!originalBanner.has_image}
                                        disableSecondaryText={!originalBanner.has_secondary_text}
                                        isEditContent={true}
                                    />

                                    <Button mt={8} size="l" use="primary" theme="info" type="submit" className={classes.generateButton} disabled={disableSubmit}>
                                        Generate banners
                                    </Button>
                                </Box>
                            </Box>
                            <Box w={"100%"} ml={6}>
                                <>
                                    {Object.values(contentGenerations).length === 0 ? (
                                        <Box>
                                            <WidgetEmpty icon={getIconPath('nothing-found')} mt={30}>
                                                <WidgetEmpty.Title>Generated banners will be displayed here</WidgetEmpty.Title>
                                                <WidgetEmpty.Description>
                                                    Fill and submit the form in order to get banners.
                                                </WidgetEmpty.Description>
                                            </WidgetEmpty>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Button mb={5} size="l" use="primary" theme="info" type="submit" className={classes.generateButton} disabled={disableSubmit}>
                                                Generate more banner design
                                            </Button>

                                            <Generations
                                                generations={contentGenerations}
                                                generationHandler={generationHandler}
                                                setGeneralInfo={setGeneralInfo}
                                                setGenerations={setContentGenerations}
                                                setGeneralError={setGeneralError}
                                                downloadBanner={downloadBanner}
                                            />
                                        </Box>
                                    )}
                                </>
                            </Box>
                        </Flex>
                    </form>
                )}
            </Form>
        </>
    )
}

export default EditContent