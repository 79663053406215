import React from 'react'
import { Box, Flex } from '@semcore/flex-box';
import DropFile from "../DropFile";
import { Field } from 'react-final-form';
import ColorPicker from "../ColorPicker";
import { Text } from '@semcore/typography';

const style = {
    dropFile: {
        height: "86px",
        background: "#FFFFFF",
        border: "2px dashed #CCCCCC",
        borderRadius: "4px",
    },
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
    containerColor: {
        marginTop: "15px"
    }
}

const Settings = ({ error }) => {
    return (
        <Flex>
            <Box>
                <Text size={400} tag="div" mb={1}>
                    2. Define your brand guidelines
                </Text>
                <Text size={200} tag="div" mb={1}>
                    The colors and ratio of the logo will be taken into account by our algorithm.
                </Text>
                {error && (<div style={style.error}> {error} </div>)}
                <div>
                    <Field name="logo">
                        {({ input, meta }) => {
                            return <DropFile title="Drag and drop your logo or" containerStyle={style.dropFile} input={input} acceptedMimeTypes={["image/svg+xml", "image/png"]}/>
                        }}
                    </Field>
                    <div style={style.containerColor}>
                        <Field name="color">
                            {({ input, meta }) => {
                                return <ColorPicker color={input.value} handleColorChange={color => {input.onChange(color)}}/>
                            }}
                        </Field>
                    </div>
                </div>
            </Box>
        </Flex>
    )
}

export default Settings