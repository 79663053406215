import React, {useEffect, useState} from 'react'
import { Box } from '@semcore/flex-box'
import Button from '@semcore/button'
import {GenerationHandler} from "../../common/GenerationHandler"
import { withRouter } from "react-router"
import { Error } from '@semcore/widget-empty'
import RepeatXS from '@semcore/icon/lib/Repeat/xs'
import Spin from '@semcore/spin'
import * as Sentry from "@sentry/browser"
import { NoticeBubble } from '@semcore/notice-bubble'
import { NoticeBubbleContainer } from '@semcore/notice-bubble'
import NavigationBar, {FAVORITES, GENERATOR} from "./NavigationBar";
import Favorites from "./Favorites";
import BannerGenerator from "./BannerGenerator";
import WidgetEmpty, { getIconPath } from '@semcore/widget-empty'
import EditFormat from "./Edit/EditFormat";
import EditContent from "./Edit/EditContent";
import Notice from '@semcore/notice';
import { Text } from '@semcore/typography';

const MAX_BANNER_TRIAL = 3

const generationHandler = new GenerationHandler()

const Dashboard = ({location}) => {
    const [generalError, setGeneralError] = useState("")
    const [generalInfo, setGeneralInfo] = useState("")
    const [fonts, setFonts] = useState([])
    const [isUserAuthent, setIsUserAuthent] = useState(false)
    const [isAppInstalled, setIsAppInstalled] = useState(false)
    const [downloadedBanners, setDownloadedBanners] = useState(0)
    const [tab, setTab] = useState(GENERATOR)
    const [mode, setMode] = useState("favorites")
    const [originalBanner, setOriginalBanner] = useState(null)
    const [userSet, setUserSet] = useState(null)

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        if (params.get("jwt") === null) {
            setGeneralError("Authentication failed.")
            return
        }

        generationHandler.setJwt(params.get("jwt"))
    }, [location])

    useEffect(() => {
        try {
            generationHandler.authentUser()
                .then(data => {
                    setIsAppInstalled(data["is_app_installed"])
                    setDownloadedBanners(data["downloaded_banners"])
                    setIsUserAuthent(true)
                })
                .catch(err => {
                    setGeneralError("Authentication failed.")
                })
        } catch (e) {
            setGeneralError("Authentication failed.")
        }
    }, [])

    useEffect(() => {
        if (isUserAuthent === true)  {
            try {
                generationHandler.getFonts()
                    .then(data => {
                        setFonts(data["records"])
                    })
                    .catch(err => {
                        setGeneralError("An error happened when trying to retrieve fonts")
                        Sentry.captureException(err)
                    })
            } catch (e) {
                setGeneralError("An error happened when trying to retrieve fonts")
            }
        }
    }, [isUserAuthent])

    useEffect(() => {
        if (!isAppInstalled && downloadedBanners >= MAX_BANNER_TRIAL) {
            if (window.hasOwnProperty("SM") && process.env.NODE_ENV === "production") {
                window.SM.client('requestAppInstall')
            }
        }
    }, [downloadedBanners])

    const downloadBanner = (bannerId) => {
        return new Promise((resolve, reject) => {
            generationHandler.downloadBanner(bannerId).then(blob => {
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement("a")
                anchor.setAttribute("href", url)
                anchor.setAttribute("download", bannerId + ".jpg")
                const clickHandler = () => {
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url)
                        anchor.removeEventListener("click", clickHandler)
                    }, 150)
                }
                anchor.addEventListener("click", clickHandler, false)
                anchor.click()

                setDownloadedBanners(downloadedBanners+1)
                resolve()
            }).catch(err => {
                setGeneralError("An error happened When trying to download banner. Please try again.")
                reject()
            })
        })
    }

    if (fonts.length === 0) {
        return (
            generalError === "" ? (
                <Spin centered size="m" theme="dark" />
            ) : (
                <Error description={"Try to reload widget. " + generalError}>
                    <Box mt={4}>
                        <Button>
                            <Button.Addon tag={RepeatXS} />
                            <Button.Text>Reload</Button.Text>
                        </Button>
                    </Box>
                </Error>
            )
        )
    }

    if (!isAppInstalled && downloadedBanners >= MAX_BANNER_TRIAL) {
        return (
            <Box>
                <WidgetEmpty icon={getIconPath('other-data')} mt={60}>
                    <WidgetEmpty.Title>You heave reached your trial download limits</WidgetEmpty.Title>
                    <WidgetEmpty.Description>
                        Unlock unlimited banner generations & downloads.
                        <Button mt={5} size="l" use="primary" theme="success" onClick={() => {
                            if (window.hasOwnProperty("SM") && process.env.NODE_ENV === "production") {
                                window.SM.client('requestAppInstall')
                            }
                        }}>
                            <Button.Text>Get unlimited banners for $15/mo</Button.Text>
                        </Button>
                    </WidgetEmpty.Description>
                </WidgetEmpty>
            </Box>
        )
    }

    return (
        <>
            <NoticeBubbleContainer />
            <NoticeBubble visible={generalError!==""} type="warning" onClose={() => setGeneralError("")}>{generalError}</NoticeBubble>
            <NoticeBubble visible={generalInfo!==""} type="info" onClose={() => setGeneralInfo("")}>{generalInfo}</NoticeBubble>

            <NavigationBar tab={tab} setTab={(currentTab) => {
                setTab(currentTab)
                if (currentTab === FAVORITES) {
                    setMode("favorites")
                }
            }}/>

            <Notice hidden={false} theme="danger" style={{marginBottom: "30px"}}>
                <Notice.Content>
                    <Text
                        bold={true}
                        my="2px"
                        size={300}
                        tag="div"
                    >
                        Notice: App Closing on September 10th
                    </Text>
                    <Text my={1} tag="div">

                        The Instant Banner Generator app will permanently close on September 10, 2024.<br/>
                        We recommend you download any important files before this date, as access will no longer be
                        available. Thank you for your understanding and apologize for any inconvenience. If you have any
                        questions, please feel free to reach out to <a href="https://www.semrush.com/kb/support/" target="_blank" rel="noopener noreferrer"> https://www.semrush.com/kb/support/</a>.
                    </Text>
                </Notice.Content>
            </Notice>

            {tab === FAVORITES && (
                <>
                    {mode === "format" ? (
                        <EditFormat
                            generationHandler={generationHandler}
                            setMode={setMode}
                            originalBanner={originalBanner}
                            userSet={userSet}
                            setGeneralError={setGeneralError}
                            setGeneralInfo={setGeneralInfo}
                            downloadBanner={downloadBanner}
                        />
                    ) : mode === "content" ? (
                        <EditContent
                            generationHandler={generationHandler}
                            setMode={setMode}
                            originalBanner={originalBanner}
                            userSet={userSet}
                            setGeneralError={setGeneralError}
                            setGeneralInfo={setGeneralInfo}
                            downloadBanner={downloadBanner}
                            fonts={fonts}
                        />
                    ) : (
                        <Favorites
                            generationHandler={generationHandler}
                            setGeneralError={setGeneralError}
                            setGeneralInfo={setGeneralInfo}
                            downloadedBanners={downloadedBanners}
                            downloadBanner={downloadBanner}
                            isAppInstalled={isAppInstalled}
                            setMode={setMode}
                            setOriginalBanner={setOriginalBanner}
                            setUserSet={setUserSet}
                        />
                    )}
                </>
            )}
            {tab === GENERATOR && (
                <BannerGenerator
                    generationHandler={generationHandler}
                    setGeneralError={setGeneralError}
                    setGeneralInfo={setGeneralInfo}
                    downloadedBanners={downloadedBanners}
                    downloadBanner={downloadBanner}
                    isAppInstalled={isAppInstalled}
                    fonts={fonts}
                />
            )}
        </>
    )
}

export default withRouter(Dashboard)

export {
    MAX_BANNER_TRIAL
}