import React, {useState} from "react";
import * as Sentry from "@sentry/browser";
import { Field, Form } from 'react-final-form'
import WidgetEmpty, { getIconPath } from '@semcore/widget-empty'
import { Box, Flex } from '@semcore/flex-box'
import Button from '@semcore/button'
import BannerCounter from "./BannerCounter";
import Formats from "./Formats";
import Settings from "./Form/Brand";
import Content from "./Form/Content";
import {array, object, string} from "yup";
import {MAX_BANNER_TRIAL} from "./Dashboard";
import Generations from "./Banners/Generations";

const style = {
    divider: {
        marginTop: "30px"
    },
    formatName: {
        fontWeight: "500",
        fontSize: "12px",
        color: "#333"
    },
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
    formComponents: {
        marginBottom: "30px"
    },
    bannerInformationContainer: {
        marginBottom: "4px"
    },
    closeButton: {
        cursor: "pointer",
    },
    generateButton: {
        width:"100%",
        marginTop:"32px"
    },
    submitButton: {
        margin:"auto",
        display:"block",
        marginBottom: "20px"
    },
    image: {
        maxWidth: "100%",
        maxHeight: "100%",
        "&:hover $hoverActions": {
            opacity: 1,
        },
    },
}

const maxPrimaryWhenSecondary = 25
const maxCharacters = 90

let schema = object().shape({
    title: string()
        .trim()
        .required("The title text is required to ensure a good understanding of your banner.")
        .min(3, "At least 3 characters are required to make convey your message")
        .test({
            name: "title_length_when_secondary",
            params: { maxPrimaryWhenSecondary },
            message: `The title text is longer than ${maxPrimaryWhenSecondary} characters. You can't have a body.`,
            test: function (value) {
                if (!this.parent.subtitle || this.parent.subtitle === "") {
                    return true
                }

                if (!value) {
                    return true
                }

                return value.length <= maxPrimaryWhenSecondary
            },
        })
        .test({
            name: "total_length",
            params: { maxCharacters },
            message: `Primary and subtitle text must have a maximum length of ${maxCharacters} characters.`,
            test: function (value) {
                if (!value) {
                    return true
                }

                let subtitle_length = 0
                if (this.parent.subtitle) {
                    subtitle_length = this.parent.subtitle.length
                }

                return value.length + subtitle_length <= maxCharacters
            },
        }),
    subtitle: string()
        .trim()
        .test("minimum_when_filled", "At least 3 characters are required to make convey your message.", function (value) {
            if (!value) {
                return true
            }

            return value.length >= 3
        }),
    logo: array().required("The logo is required.").min(1, "You must upload a logo."),
    formats: object()
        .required("Your should at least select one format.")
        .test("at_least_one_format", "Your should at least select one format.", function (formats) {
            return  !Object.values(formats).every((value) => value === false)
        }),
    cta: string()
        .trim()
        .test("minimum_when_filled", "The Call to action(CTA) must be between 3 and 15 characters.", function (value) {
            if (this.parent.formatType !== "iab") {
                return true
            }

            if (!value) {
                return false
            }

            return value.length >= 3 && value.length <=15
        }),
})

const BannerGenerator = ({generationHandler, setGeneralError, setGeneralInfo, downloadedBanners, downloadBanner, isAppInstalled, fonts}) => {
    const [errorFormat, setErrorFormat] = useState("")
    const [errorLogo, setErrorLogo] = useState("")
    const [errorTitle, setErrorTitle] = useState("")
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [formatType, setFormatType] = useState("social")
    const [generations, setGenerations] = useState({})

    return (
        <Form onSubmit={(data) => {
            setDisableSubmit(true)
            setGeneralError("")
            setErrorTitle("")
            setErrorLogo("")
            setErrorFormat("")

            schema.validate({
                title: ("title" in data) || data["title"] !== "" ? data["title"]: "",
                subtitle: ("subtitle" in data) || data["subtitle"] !== "" ? data["subtitle"]: "",
                formats: data["formats"],
                logo: ("logo" in data) || data["logo"] !== null ? data["logo"]: [],
                formatType: formatType,
                cta: ("cta" in data) || data["cta"] !== "" ? data["cta"]: "",
            }, { abortEarly: false })
                .then(() => {
                    let color = !("color" in data) || data["color"] !== "" ? data["color"]: undefined
                    let font = ("font" in data) || data["font"] !== "" ? data["font"]: undefined
                    let image = "image" in data && data["image"].length > 0 ? data["image"][0] : undefined
                    let cta = undefined

                    let fontSubtitle = ("font_subtitle" in data) || data["font_subtitle"] !== "" ? data["font_subtitle"]: undefined

                    let formats = []
                    for (const property in data["formats"]) {
                        if (data["formats"][property] === true) {
                            formats.push(property)
                        }
                    }

                    if (formatType === "iab") {
                        cta = data["cta"]
                    }

                    generationHandler.handleGeneration(data["title"], data["subtitle"], cta, data["logo"][0], image, color, font, fontSubtitle, formats, setGenerations)
                        .then(data => {
                            setDisableSubmit(false)
                        })
                        .catch(err => {
                            Sentry.captureException(err)
                            setGeneralError("An error happened during the generation. Please try again.")
                            setDisableSubmit(false)
                        })
                })
                .catch(function (err) {
                    err.inner.forEach((innerError) => {
                        if (innerError.path === "title" || innerError.path === "subtitle") {
                            setErrorTitle(innerError.errors.join())
                        } else if (innerError.path === "logo") {
                            setErrorLogo(innerError.errors.join())
                        } else if (innerError.path === "formats") {
                            setErrorFormat(innerError.errors.join())
                        } else {
                            setGeneralError(innerError.errors.join())
                        }
                    });

                    setDisableSubmit(false)
                })
        }}>
            {({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <Flex wMax={1122}>
                        <Box w={400}>
                            <Box>
                                { isAppInstalled === false && (
                                    <div style={style.formComponents}>
                                        <BannerCounter bannerCount={downloadedBanners} maxBanners={MAX_BANNER_TRIAL}/>
                                    </div>
                                )}
                                <div style={style.formComponents}>
                                    <Field name="formats">
                                        {({ input, meta }) => {
                                            return <Formats input={input} error={errorFormat} formatType={formatType} setFormatType ={setFormatType} />
                                        }}
                                    </Field>
                                </div>
                                <div style={style.formComponents}>
                                    <Settings error={errorLogo} />
                                </div>
                                <div>
                                    <Content fonts={fonts}  errorTitle={errorTitle} apiHandler={generationHandler} formatType={formatType} />
                                </div>
                                <Button
                                    size="l"
                                    theme="success"
                                    use="primary"
                                    style={style.generateButton}
                                    type="submit"
                                    disabled={disableSubmit || (!isAppInstalled && downloadedBanners >= MAX_BANNER_TRIAL)}
                                    loading={disableSubmit}
                                >
                                    Save settings & generate banners
                                </Button>
                            </Box>
                        </Box>
                        <Box w={"100%"} ml={6}>
                            <>
                                {Object.values(generations).length === 0 ? (
                                    <Box>
                                        <WidgetEmpty icon={getIconPath('nothing-found')} mt={60}>
                                            <WidgetEmpty.Title>Generated banners will be displayed here</WidgetEmpty.Title>
                                            <WidgetEmpty.Description>
                                                Fill and submit the form in order to get banners.
                                            </WidgetEmpty.Description>
                                        </WidgetEmpty>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button size="l" use="primary" theme="info" type="submit" disabled={disableSubmit} style={style.submitButton} loading={disableSubmit}>
                                            <Button.Text>Generate more banner design</Button.Text>
                                        </Button>
                                        <Generations
                                            generations={generations}
                                            generationHandler={generationHandler}
                                            setGeneralInfo={setGeneralInfo}
                                            setGenerations={setGenerations}
                                            setGeneralError={setGeneralError}
                                            downloadBanner={downloadBanner}
                                        />
                                    </Box>
                                )}
                            </>
                        </Box>
                    </Flex>
                </form>
            )}
        </Form>
    )
}

export default BannerGenerator