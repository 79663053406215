import React, {useState} from 'react'
import DropFile from "../DropFile";
import Input from '@semcore/input';
import { Field, FormSpy } from 'react-final-form';
import { Text } from '@semcore/typography';
import Counter from '@semcore/counter';
import AutoComplete from "../AutoComplete";
// import Notice from '@semcore/notice';
// import QuestionAlt from '@semcore/icon/lib/QuestionAlt/s';

const style = {
    contentContainer: {
        width: "400px",
    },
    textInput: {
        width: "400px",
        marginBottom: "16px",
    },
    textInputs: {
        marginTop: "30px"
    },
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
    dropFile: {
        width: "400px",
        height: "140px",
        background: "#FFFFFF",
        border: "2px dashed #CCCCCC",
        borderRadius: "4px",
    },
    optional: {
        color: "#757575"
    },
    label: {
        display: "flex",
        justifyContent: "space-between",
    },
    content: {
        fontSize: "12px"
    },
    infoNotice: {
        padding: "8px",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "18px",
    },
    fontContainer: {
        marginBottom: "20px"
    }
}

const Content = ({errorTitle, apiHandler, fonts, formatType, disableFont = false, disableSecondaryText = false, disableImage = false, disableTitleNumber = false, isEditContent = false}) => {
    const [disableSecondary, setDisableSecondary] = useState(false)

    return (
        <div style={style.contentContainer}>
            { disableImage === false && (
                <>
                    <Text size={400} tag="div" mb={1}>
                        {disableTitleNumber === false && (<>3. </>)} Select & import an image
                    </Text>
                    <Text size={200} tag="div" mb={1}>
                        Upload from your computer (jpeg or png). You can upload pictures with or without transparent backgrounds! Our algorithm will match them with appropriate decorations and layouts.
                    </Text>
                    <Field name="image">
                        {({ input, meta }) => {
                            return <DropFile title="Drag and drop your image or" containerStyle={style.dropFile} input={input} acceptedMimeTypes={["image/png", "image/jpeg"]} apiHandler={apiHandler} activeUnsplash={true} />
                        }}
                    </Field>
                </>
            )}

            <div style={style.textInputs}>
                <Text size={400} tag="div" mb={1}>
                    {disableTitleNumber === false && (<>4. </>)} Fill your banner content
                </Text>
                <Text size={200} tag="div" mb={1}>
                    Title & Body content should not exceed 90 characters. Body content is disabled when the title's length is longer than 25.
                </Text>
                {/*<Notice theme="info" style={style.infoNotice}>*/}
                {/*    <Notice.Label>*/}
                {/*        <QuestionAlt />*/}
                {/*    </Notice.Label>*/}
                {/*    <Notice.Content style={style.content}>*/}
                {/*        Title & Body content should not exceed 90 characters.<br/>*/}
                {/*        Body content is disabled when the title's length is longer than 25.*/}
                {/*    </Notice.Content>*/}
                {/*</Notice>*/}
                {errorTitle && (<div style={style.error}> {errorTitle} </div>)}
                <FormSpy
                    render={({ form }) => (
                        <Field name="title">
                            {({ input, meta }) => {
                                return (
                                    <>
                                        <Text size={200} tag="label" mb={1} htmlFor="title" style={style.label}>
                                            <span>
                                                Title
                                                <Counter ml={1}>{`${input.value.length}`}</Counter>
                                            </span>
                                            { isEditContent === true && (
                                                <span style={style.optional}>
                                                    (optional)
                                                </span>
                                            )}
                                        </Text>
                                        <Input style={style.textInput} size="l" state="normal" onChange={()=>{
                                            if (input.value.length > 25) {
                                                setDisableSecondary(true)
                                                form.change("subtitle", "")
                                            } else {
                                                setDisableSecondary(false)
                                            }
                                        }}>
                                            <Input.Value placeholder="Title" {...input} />
                                        </Input>
                                    </>
                                )
                            }}
                        </Field>
                    )}
                />

                {disableFont === false && (
                    <div style={style.fontContainer}>
                        <Field name="font">
                            {({ input, meta }) => {
                                return <AutoComplete text="Select title font" formInput={input} options={fonts} optionKey="id" optionValue="name"/>
                            }}
                        </Field>
                    </div>
                )}

                { disableSecondaryText === false && (
                    <Field name="subtitle">
                        {({ input, meta }) => {
                            return (
                                <>
                                    <Text size={200} tag="label" mb={1} htmlFor="body" style={style.label}>
                                        <span>
                                            Body
                                            <Counter ml={1}>{`${input.value.length}`}</Counter>
                                        </span>
                                        <span style={style.optional}>
                                            (optional)
                                        </span>
                                    </Text>
                                    <Input style={style.textInput} size="l" state="normal" >
                                        <Input.Value
                                            placeholder="Body"
                                            {...input}
                                            disabled={disableSecondary}
                                        />
                                    </Input>
                                </>
                            )
                        }}
                    </Field>
                )}

                {disableFont === false && (
                    <div style={style.fontContainer}>
                        <Field name="font_subtitle">
                            {({ input, meta }) => {
                                return <AutoComplete text="Select body font" formInput={input} options={fonts} optionKey="id" optionValue="name"/>
                            }}
                        </Field>
                    </div>
                )}

                {formatType === "iab" &&(
                    <Field name="cta">
                        {({ input, meta }) => {
                            return (
                                <>
                                    <Text size={200} tag="label" mb={1} htmlFor="body" style={style.label}>
                                    <span>
                                        Call to action (CTA)
                                        <Counter ml={1}>{`${input.value.length}`}</Counter>
                                    </span>
                                    { isEditContent === true && (
                                        <span style={style.optional}>
                                                (optional)
                                        </span>
                                    )}
                                    </Text>
                                    <Input style={style.textInput} size="l" state="normal" >
                                        <Input.Value
                                            placeholder="CTA text"
                                            {...input}
                                        />
                                    </Input>
                                </>
                            )
                        }}
                    </Field>
                )}
            </div>
        </div>
    )
}

export default Content