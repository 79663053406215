import {getHeightFromFormat, getNameFromFormat} from "../../../common/format";
import Button from '@semcore/button'
import { Box, Flex } from '@semcore/flex-box'
import { Text } from '@semcore/typography'
import MathPlusM from '@semcore/icon/lib/MathPlus/s'
import Skeleton from '@semcore/skeleton'
import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import Close from '@semcore/icon/lib/Close/s'
import FileDownload from '@semcore/icon/lib/FileDownload/s'


const useStyles = createUseStyles({
    generationsContainer: {
        display: "flex",
        flexDirection: "column-reverse",
    },
    generationContainer: {
        background: "#f5f5f5",
        borderRadius: "4px",
        position: "relative",
        padding: "8px",
        marginBottom:"40px",
    },
    bannerContainer: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        "&:hover $hoverActions": {
            opacity: 1,
        },
    },
    image: {
        maxWidth: "100%",
        maxHeight: "100%",
        "&:hover $hoverActions": {
            opacity: 1,
        },
    },
    hoverActions: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(5,9,26, 0.8)",
        transition: ".5s ease",
        opacity: 0,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    downloadButton: {
        width: "94px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    favoriteButton: {
        width: "126px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
    },
    closeButton: {
        cursor: "pointer",
    },
})

const Generations = ({ generations, generationHandler, setGeneralInfo, setGenerations, setGeneralError, downloadBanner }) => {
    const classes = useStyles()
    const [disableButton, setDisableButton] = useState(false)

    const addToFavorites = (bannersId, uniqueId) => {
        setDisableButton(true)
        generationHandler.addBannersToFavorite(bannersId, generations[uniqueId].userSetId)
            .then(() => {
                let banners = [
                    ...generations[uniqueId].banners
                ]

                banners.forEach(banner => {
                    if (bannersId.includes(banner.id)) {
                        banner.addedToFavorite = true
                    }
                })

                let generationsState = {
                    ...generations,
                    [uniqueId]: {
                        ...generations[uniqueId],
                        banners: banners,
                        addedToFavorite: banners.every(banner => banner.addedToFavorite === true)
                    }

                }

                setGeneralInfo(
                    bannersId.length === 1 ? "The banner has been added to Favorites": "Banners have been added to Favorites"
                )
                setGenerations(generationsState)
            }).catch(err => {
                setGeneralError("An error happened When trying to add banners to favorite. Please try again.")
            }).finally(() => {
                setDisableButton(false)
        })
    }

    const removeGenerationByUniqueId = (uniqueId) => {
        let tmpGeneration = {
            ...generations,
        }

        delete tmpGeneration[uniqueId]

        setGenerations(tmpGeneration)
    }

    return (
        <div className={classes.generationsContainer}>
            {Object.values(generations).map(generation => {
                return (
                    <Box key={generation.uniqueId} p={2} className={classes.generationContainer}>
                        <Flex ml={3} mb={3} justifyContent="space-between" alignItems="center">
                            <Flex>
                                <Text size={400} tag="div">
                                    Banner set {generation.uniqueId.split("-")[0]}
                                </Text>
                                {generation.addedToFavorite === false && (
                                    <Button
                                        ml={5}
                                        size="m"
                                        theme="muted"
                                        use="secondary"
                                        type="button"
                                        disabled={disableButton}
                                        loading={disableButton}
                                        onClick={e => {
                                            const bannersId = generation.banners.map((banner) => {
                                                return banner.id
                                            })

                                            addToFavorites(bannersId, generation.uniqueId)
                                            e.preventDefault()
                                        }}
                                    >
                                        <Button.Addon tag={MathPlusM} />
                                        <Button.Text>Add all banners to favorites</Button.Text>
                                    </Button>
                                )}
                            </Flex>
                            <Close className={classes.closeButton} onClick={()=>{removeGenerationByUniqueId(generation.uniqueId)}} />
                        </Flex>

                        <Flex flexWrap="wrap">
                            {generation.banners.map((banner, index) => {
                                return  <Box key={generation.uniqueId + index} mb={5} ml={3}>
                                    {generation.generated === true ? (
                                        <>
                                            <Text mb={1} color="gray20" size={100}>{getNameFromFormat(banner.format)}</Text>
                                            <div className={classes.bannerContainer} key={"banner-container-" + banner.id} style={
                                                {
                                                    height: getHeightFromFormat(banner.format),
                                                }
                                            }>
                                                <img alt={"banner "+ banner.format} src={banner.url} className={classes.image} />
                                                <div className={classes.hoverActions}>
                                                    <Button
                                                        className={classes.downloadButton}
                                                        size="m"
                                                        use="secondary"
                                                        theme="success"
                                                        type="button"
                                                        disabled={disableButton}
                                                        loading={disableButton}
                                                        onClick={e => {
                                                            setDisableButton(true)
                                                            downloadBanner(banner.id)
                                                                .finally(() => {
                                                                    setDisableButton(false)
                                                                })

                                                            e.preventDefault()
                                                        }}
                                                    >
                                                        <Button.Addon tag={FileDownload} />
                                                        <Button.Text>Download</Button.Text>
                                                    </Button>
                                                    {banner.addedToFavorite === false && (
                                                        <Button
                                                            className={classes.favoriteButton}
                                                            size="m"
                                                            use="secondary"
                                                            theme="success"
                                                            type="button"
                                                            disabled={disableButton}
                                                            loading={disableButton}
                                                            onClick={e => {
                                                                addToFavorites([banner.id], generation.uniqueId)
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            <Button.Addon tag={MathPlusM} />
                                                            <Button.Text>Add to favorites</Button.Text>
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <Skeleton height={"192px"} width={banner.format === "FB_SQUARE" ? "170px" : "324px"}>
                                            <rect height="18px" width="120px" />
                                            <rect y={"22px"} width={banner.format === "FB_SQUARE" ? "170px" : "340px"}   height={"170px"}/>
                                        </Skeleton>
                                    )}
                                </Box>
                            })}
                        </Flex>
                    </Box>
                )
            })}
        </div>
    )
}

export default Generations