import React from "react";
import { Box, Flex } from '@semcore/flex-box';
import { Text } from '@semcore/typography';

const Maintenance = () => {
    return (
        <Flex justifyContent="space-evenly">
            <Box wMax={400} mt={28}>
                <Text mb={4} tag="div" size={500}>The Abyssale app is currently under maintenance</Text>
                <Text tag="div" size={300}>
                    Abyssale is currently undergoing maintenance.<br/>
                    We’ll be back soon. Thank you for your patience.
                </Text>
            </Box>
            <Box>
                <img width="320px" height="285px" src="/images/maintenance.png" alt="Maintenance" />
            </Box>
        </Flex>
    )
}

export default Maintenance