import Breadcrumbs from '@semcore/breadcrumbs'
import { Form } from 'react-final-form'
import { Box, Flex } from '@semcore/flex-box'
import { Text } from '@semcore/typography'
import {createUseStyles} from "react-jss"
import React, {useState} from "react"
import {getFormats, getTypeByFormat} from "../../../common/format";
import Button from '@semcore/button'
import {object} from "yup";
import { Field } from 'react-final-form'
import EditFormatCheckboxes from "./EditFormatCheckboxes";
import * as Sentry from "@sentry/browser";
import WidgetEmpty, { getIconPath } from '@semcore/widget-empty'
import Generations from "../Banners/Generations";
import BannerPreview from "./BannerPreview";

const useStyles = createUseStyles({
    formatsContainer: {
        marginTop: "16px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    generateButton: {
        width:"100%",
    },
    error: {
        color: "#ff7f00",
        fontSize: "12px",
    },
})

let schema = object().shape({
    formats: object()
        .required("Your should at least select one format.")
        .test("at_least_one_format", "Your should at least select one format.", function (formats) {
            return  !Object.values(formats).every((value) => value === false)
        }),
})

const EditFormat = ({ generationHandler, setMode, userSet, originalBanner, setGeneralError, setGeneralInfo, downloadBanner }) => {
    const classes = useStyles()
    const [error, setError] = useState(null)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [formatGenerations, setFormatGenerations] = useState({})

    const type = getTypeByFormat(originalBanner.format)
    let formats = getFormats()[type]
    formats = formats.filter(function(format) {
        return format.id !== originalBanner.format
    })

    return (
        <>
            <Breadcrumbs mb={6}>
                <Breadcrumbs.Item onClick={() => {setMode("favorites")}}>
                    Favorites
                </Breadcrumbs.Item>
                <Breadcrumbs.Item active>
                    Edit format
                </Breadcrumbs.Item>
            </Breadcrumbs>

            <Form onSubmit={(data) => {
                setDisableSubmit(true)

                schema.validate({formats: data["formats"]}, { abortEarly: false })
                    .then(() => {
                        setError("")

                        let formats = []
                        for (const property in data["formats"]) {
                            if (data["formats"][property] === true) {
                                formats.push(property)
                            }
                        }

                        generationHandler.handleFormatGeneration(formats, originalBanner, userSet.id, setFormatGenerations)
                            .then(data => {
                                setDisableSubmit(false)
                            })
                            .catch(err => {
                                Sentry.captureException(err)
                                setGeneralError("An error happened during the generation. Please try again.")
                                setDisableSubmit(false)
                            })
                    })
                    .catch(function (err) {
                        err.inner.forEach((innerError) => {
                            setError(innerError.errors.join())
                        });

                        setDisableSubmit(false)
                    })
            }}>
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <Flex wMax={1122}>
                            <Box w={400}>
                                <BannerPreview originalBanner={originalBanner} userSet={userSet} />

                                <Box mt={8} w={400}>
                                    <Text size={300} tag="div" mb={1}>
                                        Select banner formats
                                    </Text>
                                    <Text size={100} tag="div" mb={1}>
                                        You can select one or all available social formats at the same time.
                                    </Text>
                                    {error && (<div className={classes.error}> {error} </div>)}
                                    <Box className={classes.formatsContainer}>
                                        <Field name="formats">
                                            {({ input, meta }) => {
                                                return <EditFormatCheckboxes input={input} formats={formats} />
                                            }}
                                        </Field>
                                    </Box>

                                    <Button mt={8} size="l" use="primary" theme="info" type="submit" className={classes.generateButton} disabled={disableSubmit}>
                                        Generate banners
                                    </Button>
                                </Box>
                            </Box>
                            <Box w={"100%"} ml={6}>
                                <>
                                    {Object.values(formatGenerations).length === 0 ? (
                                        <Box>
                                            <WidgetEmpty icon={getIconPath('nothing-found')} mt={30}>
                                                <WidgetEmpty.Title>Generated banners will be displayed here</WidgetEmpty.Title>
                                                <WidgetEmpty.Description>
                                                    Fill and submit the form in order to get banners.
                                                </WidgetEmpty.Description>
                                            </WidgetEmpty>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Button mb={5} size="l" use="primary" theme="info" type="submit" className={classes.generateButton} disabled={disableSubmit}>
                                                Generate more banner design
                                            </Button>

                                            <Generations
                                                generations={formatGenerations}
                                                generationHandler={generationHandler}
                                                setGeneralInfo={setGeneralInfo}
                                                setGenerations={setFormatGenerations}
                                                setGeneralError={setGeneralError}
                                                downloadBanner={downloadBanner}
                                            />
                                        </Box>
                                    )}
                                </>
                            </Box>
                        </Flex>
                    </form>
                )}
            </Form>
        </>
    )
}

export default EditFormat