import React from "react";
import TabPanel from '@semcore/tab-panel'
import Badge from '@semcore/badge'

const GENERATOR = "generator"
const FAVORITES = "favorites"

const NavigationBar = ({tab, setTab}) => {
    return (
        <TabPanel value={tab} onChange={setTab} style={{marginBottom: "44px"}}>
            <TabPanel.Item value={GENERATOR}>
                Banner generator
            </TabPanel.Item>
            <TabPanel.Item value={FAVORITES}>
                <TabPanel.Item.Text>Favorites</TabPanel.Item.Text>
                <TabPanel.Item.Addon>
                    <Badge bg="green">new</Badge>
                </TabPanel.Item.Addon>
            </TabPanel.Item>
        </TabPanel>
    )
}

export default NavigationBar

export {
    GENERATOR,
    FAVORITES
}