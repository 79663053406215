import {Api} from "./api";
import { v4 as uuidv4 } from 'uuid';

export class GenerationHandler {
    constructor() {
        this.api = new Api()
        this.lastGeneration = {}
        this.funnelId = undefined
        this.lastContentGeneration = {}
        this.contentFunnelId = undefined
        this.previousBannerId = undefined
    }

    setJwt(jwt) {
        this.api.setJwt(jwt)
    }

    async setJwtFromSm() {
        if (window.hasOwnProperty("SM") && process.env.NODE_ENV === "production") {
            const jwt = await window.SM.client('getAccessToken')
            this.api.setJwt(jwt)
        }
    }

    async handleGeneration(title, subtitle, cta, logo, image, color, font, fontSubtitle, formats, setGenerations) {
        await this.setJwtFromSm()

        if (this.lastGeneration.title !== title || this.lastGeneration.subtitle !== subtitle
            || this.lastGeneration.logo !== logo  || this.lastGeneration.image !== image
            || this.lastGeneration.color !== color || this.lastGeneration.font !== font
            || this.lastGeneration.fontSubtitle !== fontSubtitle ||this.lastGeneration.cta !== cta) {
            const funnel = await this.api.createFunnel(title, subtitle, cta, logo, image, color, font, fontSubtitle)

            this.funnelId = funnel["funnel_id"]
            this.lastGeneration = {
                title: title,
                subtitle: subtitle,
                logo: logo,
                image: image,
                color: color,
                font: font,
                fontSubtitle: fontSubtitle,
                cta: cta,
            }
        }

        let bannerCounter = 4
        let multiFormat = false
        if (formats.length > 1) {
            bannerCounter = formats.length
            multiFormat = true
        }

        let counter = 0
        let errorCounter = 0
        let generation = {
            uniqueId:  uuidv4(),
            funnelId: this.funnelId,
            banners: [],
            generated: false,
            addedToFavorite: false,
            userSetId:  uuidv4(),
        }

        for (let i = 0; i < bannerCounter; i++) {
            generation.banners.push({
                format: multiFormat === false ? formats[0] : formats[i],
            })
        }


        setGenerations(generations => ({
            ...generations,
            [generation.uniqueId]: generation
        }))

        while(counter < bannerCounter) {
            if (errorCounter >= 2) {
                throw Error("Impossible to generate banner")
            }

            let bannerUrl
            let bannerId
            if (multiFormat === false || counter === 0) {
                try {
                    const banner = await this.api.generateBanner(this.funnelId, formats[0])
                    this.previousBannerId = banner["id"]
                    const bannerContent = await this.api.renderBanner(banner["id"])
                    bannerId = banner["id"]
                    bannerUrl = URL.createObjectURL(bannerContent)
                    errorCounter= 0
                } catch(e) {
                    errorCounter++
                    continue
                }
            }  else {
                try {
                    // vary format for other banners
                    const banner = await this.api.varyBanner(this.funnelId, formats[counter], this.previousBannerId)
                    const bannerContent = await this.api.renderBanner(banner["id"])
                    bannerId = banner["id"]
                    bannerUrl = URL.createObjectURL(bannerContent)
                    errorCounter= 0
                } catch(e) {
                    errorCounter++
                    continue
                }
            }

            generation.generated = true
            generation.banners[counter]["id"] = bannerId
            generation.banners[counter]["url"] = bannerUrl
            generation.banners[counter]["generated"] = true
            generation.banners[counter]["addedToFavorite"] = false

            counter++
        }
    }

    async handleFormatGeneration(formats, originalBanner, userSetId, setFormatGenerations) {
        await this.setJwtFromSm()

        let counter = 0
        let errorCounter = 0
        let generation = {
            uniqueId:  uuidv4(),
            banners: [],
            generated: false,
            addedToFavorite: false,
            userSetId: userSetId
        }

        for (let i = 0; i < formats.length; i++) {
            generation.banners.push({
                format: formats[i],
                id: null,
                url: null,
                addedToFavorite: false,
            })
        }

        setFormatGenerations(generations => ({
            ...generations,
            [generation.uniqueId]: generation
        }))

        while(counter < formats.length) {
            if (errorCounter >= 2) {
                throw Error("Impossible to generate banner")
            }

            try {
                const banner = await this.api.varyBanner(originalBanner.funnel_id, formats[counter], originalBanner.id)
                const bannerContent = await this.api.renderBanner(banner["id"])
                errorCounter= 0

                generation.generated = true
                generation.banners[counter]["id"] = banner["id"]
                generation.banners[counter]["url"] = URL.createObjectURL(bannerContent)

                counter++
            } catch(e) {
                errorCounter++
            }
        }
    }

    async handleContentGeneration(title, subtitle, cta, image, bannerId, userSetId, bannerFormat, setContentGenerations) {
        await this.setJwtFromSm()

        if (this.lastContentGeneration.title !== title || this.lastContentGeneration.subtitle !== subtitle
            || this.lastContentGeneration.image !== image || this.lastContentGeneration.cta !== cta
            || this.lastContentGeneration.bannerId !== bannerId) {

            const funnel = await this.api.createContentFunnel(title, subtitle, cta, image, bannerId)

            this.contentFunnelId = funnel["funnel_id"]
            this.lastContentGeneration = {
                title: title,
                subtitle: subtitle,
                image: image,
                cta: cta,
                bannerId : bannerId
            }
        }

        let generated = false
        let errorCounter = 0
        let generation = {
            uniqueId:  uuidv4(),
            banners: [{
                format: bannerFormat,
                id: null,
                url: null,
                addedToFavorite: false,
            }],
            generated: false,
            addedToFavorite: false,
            userSetId: userSetId
        }


        setContentGenerations(generations => ({
            ...generations,
            [generation.uniqueId]: generation
        }))

        while(generated === false) {
            if (errorCounter >= 2) {
                throw Error("Impossible to generate banner")
            }

            try {
                const banner = await this.api.varyContent(this.contentFunnelId)
                const bannerContent = await this.api.renderBanner(banner["id"])
                errorCounter = 0

                generation.generated = true
                generation.banners[0]["id"] = banner["id"]
                generation.banners[0]["url"] = URL.createObjectURL(bannerContent)

                generated = true
            } catch(e) {
                errorCounter++
            }
        }
    }

    async downloadBanner(bannerId) {
        await this.setJwtFromSm()

        return await this.api.downloadBanner(bannerId)
    }

    async addBannersToFavorite(bannersId, uniqueId) {
        await this.setJwtFromSm()

        return await this.api.addBannersToFavorite(bannersId, uniqueId)
    }

    async deleteBannersFromFavorite(bannersId, uniqueId) {
        await this.setJwtFromSm()

        return await this.api.deleteBannersFromFavorite(bannersId, uniqueId)
    }

    async getUserSets(currentPage) {
        await this.setJwtFromSm()

        return await this.api.getUserSets(currentPage)
    }

    async getFonts() {
        await this.setJwtFromSm()

        return this.api.getFonts()
    }

    async authentUser() {
        await this.setJwtFromSm()

        return this.api.authentUser()
    }

    async getUnsplashImages(term, page, perPage) {
        await this.setJwtFromSm()

        return this.api.getUnsplashImages(term, page, perPage)
    }

    async downloadUnsplashPhoto(photoId) {
        await this.setJwtFromSm()

        return this.api.downloadUnsplashPhoto(photoId)
    }
}

const generationHandler = new GenerationHandler()

export default generationHandler