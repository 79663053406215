const getNameFromFormat = (format) => {
    switch (format) {
        case "FB_FEED":
            return "Facebook - Feed post";
        case "FB_SQUARE":
            return "Facebook - Feed square";
        case "TW_FEED":
            return "Twitter - Feed post";
        case "TW_DMC":
            return "Twitter - Promoted post";
        case "LI_FEED":
            return "Linkedin - Feed post";
        case "INSTAGRAM_SQUARE":
            return "Instagram - Feed square";
        case "IAB_300x250":
            return "IAB - Medium rectangle";
        case "IAB_728x90":
            return "IAB - Leaderboard";
        case "IAB_300x600":
            return "IAB - Skyscraper";
        default:
            return "unknown";
    }
}

const getHeightFromFormat = (format) => {
    switch (format) {
        case "IAB_300x250":
            return "250px";
        case "IAB_728x90":
            return "90px";
        case "IAB_300x600":
            return "300px";
        default:
            return "170px";
    }
}


const getHeightFromFormatForFavoritesBanner = (format) => {
    switch (format) {
        case "IAB_300x250":
            return "250px";
        case "IAB_728x90":
            return "90px";
        case "IAB_300x600":
            return "350px";
        default:
            return "250px";
    }
}

const getTypeByFormat = (format) => {
    switch (format) {
        case "FB_FEED":
            return "social";
        case "FB_SQUARE":
            return "social";
        case "TW_FEED":
            return "social";
        case "TW_DMC":
            return "social";
        case "LI_FEED":
            return "social";
        case "INSTAGRAM_SQUARE":
            return "social";
        case "IAB_300x250":
            return "iab";
        case "IAB_728x90":
            return "iab";
        case "IAB_300x600":
            return "iab";
        default:
            throw Error("Format type not found")
    }
}

const getFormats = () => {
    return {
        "social": [
            {
                id: "FB_FEED",
                name: "Feed post",
                description: "Rectangle (1.91:1)",
                image: "/logo/facebook.svg",
                color: "rgba(24, 119, 242, 0.1)",
            },
            {
                id: "FB_SQUARE",
                name: "Feed square",
                description: "Square (1:1)",
                image: "/logo/facebook.svg",
                color: "rgba(24, 119, 242, 0.1)",
            },
            {
                id: "TW_FEED",
                name: "Tweet post",
                description: "Rectangle (16:9)",
                image: "/logo/twitter.svg",
                color: "rgba(29, 161, 242, 0.1)",
            },
            {
                id: "TW_DMC",
                name: "Promoted tweet",
                description: "Rectangle (1.91:1)",
                image: "/logo/twitter.svg",
                color: "rgba(29, 161, 242, 0.1)",
            },
            {
                id: "LI_FEED",
                name: "Feed post",
                description: "Rectangle (1.91:1)",
                image: "/logo/linkedin.svg",
                color: "rgba(10, 102, 194, 0.1)",
            },
            {
                id: "INSTAGRAM_SQUARE",
                name: "Feed square",
                description: "Square (1:1)",
                image: "/logo/instagram.svg",
                color: "#FFEADE",
            },
        ],
        "iab": [
            {
                id: "IAB_300x250",
                name: "IAB 300x250",
                description: "Medium rectangle",
                image: "/logo/google.svg",
                color: "rgba(24, 119, 242, 0.1)",
            },
            {
                id: "IAB_300x600",
                name: "IAB 300x600",
                description: "Skyscraper",
                image: "/logo/google.svg",
                color: "rgba(24, 119, 242, 0.1)",
            },
            {
                id: "IAB_728x90",
                name: "IAB 728x90",
                description: "Leaderboard",
                image: "/logo/google.svg",
                color: "rgba(24, 119, 242, 0.1)",
            },
        ]
    };
}



export {
    getNameFromFormat,
    getHeightFromFormat,
    getFormats,
    getTypeByFormat,
    getHeightFromFormatForFavoritesBanner
}